<template>
  <div class="no-select">
    <div v-if="checkInStatus == false">
      <v-row>
        <v-col cols="12" sm="5">
          <v-container>
            <getRescheduleTime :eventId="selectedEvent.eventId" />
            <patientInformationDisplay v-if="oneUser.UserId" :selectedEvent="oneUser" :key="oneUser.UserId" />
            <v-card outlined v-if="!selectedEvent.missed">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Edit Services</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template>
                      <v-data-table :headers="ProviderServiceHeaders" :items="providerServicePairs" hide-default-header
                        hide-default-footer show-expand class="elevation-1">
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            Note : {{ item.service.perVisitDetails }}
                          </td>
                        </template>
                        <template v-slot:[`item.actions`]="{ item, index }">
                          <v-icon small class="mr-2" v-if="index !== 0"
                            @click="deleteTimeSlot(item)">mdi-delete</v-icon>
                        </template>
                      </v-data-table>
                    </template>
                    <br />
                    <v-card>
                      <v-container>
                        <providerSelector @providerSelected="onProviderSelected" :locationId="locationId"
                          :key="locationId" />
                        <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerId"
                          :locationId="locationId" :key="providerId + '-' + locationId" />
                        {{ timeSlotLengthToDisplay }}
                        <v-text-field v-model="perVisitDetails" label="Per Visit Details" outlined dense></v-text-field>
                        <v-btn @click="addServedList" text color="primary">
                          Add This New Service
                        </v-btn>
                      </v-container>
                    </v-card>

                    <v-card-actions class="justify-center">
                      <v-btn text color="primary" @click="saveEvent()">
                        Save</v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Reschedule Appointment</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="7">
                        <v-select v-model="locationChange" :item-text="'companyName'" :item-value="'companyName'"
                          :items="locationsList" label="Location" dense outlined @change="fetchLocationEvents($event)"
                          return-object></v-select>
                        <br />
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-btn color="primary" class="mr-4" @click="changeLocation">Change</v-btn>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="today" type="date" @input="getDayEventByDateSelection(today)"
                      label="Date (Year/Month/Day)" />
                    <br />
                    <v-btn color="primary" class="mr-4" @click="rescheduleEvent()">Change Time</v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Actions</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>


                    <br />

                    <!-- 
                    <div>
                      <v-btn text @click="sendEmailReminder()"> Send Email Reminder</v-btn>
                    </div> -->


                    <!-- <div>
                      <v-btn text @click="sendTextReminder()">Send Phone Reminder </v-btn>
                    </div> -->


                    <v-btn text color="red" @click="cancelEvent()">Cancel Event </v-btn>

                    <br>
                    <v-btn text color="red" @click="missedEvent()"> Missed </v-btn>

                    <v-icon small class="mr-2" @click="deleteEvent(item)" style="float: right;"
                      color="red">mdi-delete</v-icon>

                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>





              <v-card-actions class="justify-center">
                <v-btn text @click="lateEvent()">Late </v-btn>
                <v-btn text color="primary" @click="checkInEvent()">
                  Check In
                </v-btn>
                <v-snackbar v-model="snackbar" :timeout="3000" color="success">
                  Missed Event Confirmed!
                  <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                </v-snackbar>

                <v-snackbar v-model="loading" :timeout="-1" color="info">
                  Loading...
                </v-snackbar>
              </v-card-actions>
            </v-card>

            <v-card outlined v-if="selectedEvent.missed">This is an missed event</v-card>
            <v-btn text color="red" v-if="selectedEvent.missed" @click="undoMissedEvent()"> Undo Missed Event </v-btn>
          </v-container>
        </v-col>
        <v-col cols="12" sm="7">
          <v-calendar ref="calendar" v-model="today" type="category" :events="events" :now="today" :value="today"
            :categories="categoryName" :first-interval="6" :interval-minutes="60" interval-height="70"
            :interval-count="18" @mousedown:event="startDrag" @mousemove:time="mouseMove" @mouseup:time="endDrag"
            @mousedown:time-category="startTimeDayCategory" @mousemove:time-category="mouseMoveTimeCategory"
            @mouseleave.native="cancelDrag">
            <template v-slot:event="{ eventSummary }">
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
            </template>
          </v-calendar>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <br />
      <v-card>
        <v-container>
          <v-card-title>Events and Related Events</v-card-title>
          <v-card-text>
            <div v-for="(event, index) in allRelatedEvents" :key="index">
              Service {{ index + 1 }} : {{ event.provider[0].service[0].serviceName }} <br />
            </div>
          </v-card-text>
        </v-container>
      </v-card>
      <br />


      <!-- Trigger Button for Voice Recognition Dialog -->
      <v-btn color="primary" @click="voiceReconDialog = true">
        Start Voice Recognition
      </v-btn>
      <br /><br />
      <v-btn color="primary" @click="medManDialog = true,newMedicalPageCounter++">
        Medicine Management
      </v-btn>
      <v-dialog class="higher-z-index" width="800px" v-model="medManDialog" persistent>
        <v-card class="d-flex flex-column">
          <v-toolbar text color="primary" dark>
            <v-toolbar-title>Medicine Management</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="medManDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container class="flex-grow-1 overflow-y-auto">
            <userMedicalManagement  @close="medManDialog = false" :userId="oneUser.UserId"  :selectedEvent="oneUser" :key="newMedicalPageCounter"/>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog class="higher-z-index" width="800px" v-model="voiceReconDialog" persistent>
        <v-card class="d-flex flex-column">
          <v-toolbar text color="primary" dark>
            <v-toolbar-title>Voice Recognition</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="voiceReconDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container class="flex-grow-1 overflow-y-auto">
            <voiceRecon :userId="oneUser.UserId" />
          </v-container>
        </v-card>
      </v-dialog>





      <patientInformationDisplay v-if="oneUser.UserId" :selectedEvent="oneUser" :key="oneUser.UserId" />
      <v-container>
        <v-card class="mx-auto" outlined>
          <v-container>
            <v-card-title>Invoice & Receipt</v-card-title>
            <v-list-item>
              <v-btn @click="newReceiptPage = true, newReceiptCounter++">Receipt</v-btn>
              <v-spacer />
              <v-btn @click="invoiceOpen = true, invoiceCounter++">Invoice</v-btn>
              <br />
            </v-list-item>

          </v-container>

        </v-card>
      </v-container>
      <v-card-actions class="justify-center">
        <v-btn text color="secondary" @click="undoCheckInEvent()">
          Undo Check In
        </v-btn>
      </v-card-actions>
    </div>


    <v-dialog v-model="eventLocationChangedDialog" max-width="290" persistent>
      <v-card>
        <v-card-title>Event Location Changed, Press OK to go to the new
          Location</v-card-title>
        <v-card-actions>
          <v-btn text @click="confirmAndReload">OK</v-btn>
          <v-btn text @click="reload">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="invoiceOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Invoice & Receipt Management Page</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="invoiceOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <invoicePage :selectedEvent="selectedEvent" :key="invoiceCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="newReceiptPage" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Invoice & Receipt Management Page</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="newReceiptPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <receiptPage :selectedEvent="selectedEvent" @close="newReceiptPage = false" :key="newReceiptCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import 'moment-timezone';
export default {
  name: "dailyAppointmentDetailPage",
  props: ["selectedEvent", "checkInStatus"],
  data: () => ({
    changedEvent: null,
    oneUser: [],
    value: "",
    events: [],
    colors: [
      "#2196F3",
      "#3F51B5",
      "#673AB7",
      "#00BCD4",
      "#4CAF50",
      "#FF9800",
      "#757575",
    ],
    dragEvent: null,
    voiceReconDialog: false,
    medManDialog: false,
    invoiceCounter: 0,
    newReceiptCounter: 0,
    newReceiptPage: false,
    invoiceOpen: false,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    providerId: null,
    perVisitDetails: null,
    providerServicePairs: [],
    eventLocationChangedDialog: false,
    singleExpand: true,
    locationsList: [],
    locationChange: null,
    ProviderServiceHeaders: [
      {
        text: "Provider",
        align: "start",
        value: "provider.providerName",
      },
      { text: "Service", value: "service.serviceName" },
      { text: "Time", value: "service.difference" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    loading: false,
    snackbar: false,
    categoryName: [],
    eventLocation: [],

    locationId: null,
    today: new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    providerAdd: null,
    serviceAdd: null,
    service_type_dropdown: [],
    provider_name_dropdown: [],
    timeSlotLengthToDisplay: "",
    doctor_list_dropdown: [],
    allRelatedEvents: [],
    newMedicalPageCounter:0,
  }),

  async mounted() {

    this.locationId = this.selectedEvent.location.location.locationId;
    await this.getUserInfo(this.selectedEvent.UserId);
    this.selectedEvent.provider.forEach((doc) => {
      doc.service[0].difference = this.getTimeLengthDifference(
        this.selectedEvent.end - this.selectedEvent.start
      );
      this.categoryName.push(doc.provider.providerName);

      this.providerServicePairs.push({
        provider: doc.provider,
        service: doc.service[0],
      });
    });
    this.today = new Date(this.selectedEvent.start).toISOString().substr(0, 10);
    this.eventLocation = this.selectedEvent.location.location;
    this.locationChange = this.eventLocation
    this.getAllRelatedEvents()
    await this.updateDoctorList();
    this.getDayEvent();
    this.getAllLocationProviderWorks();
  },
methods: {
  toggleChat() {
    this.chatOpen = !this.chatOpen;
  },
  async sendMessage() {
    if (this.userMessage.trim() !== '') {
      // Add user's message to the chat
      this.messages.push({
        id: this.messageId++,
        text: this.userMessage,
        sender: 'user',
      });

      const messageToSend = this.userMessage; // Capture the message to send
      this.userMessage = ''; // Clear the input field

      try {
        // Send the message to the backend via Axios
        const response = await this.$axios({
          method: 'post',
          url: this.$axios.defaults.baseURL + '/api/chatbot/message',
          headers: { Authorization: localStorage.getItem('accessToken') },
          data: {
            message: messageToSend, // Send the message content to the backend
          },
        });

        // Add the response from the bot to the chat
        this.messages.push({
          id: this.messageId++,
          text: response.data.reply, // Assuming the response has a "reply" field with the bot's response
          sender: 'bot',
        });
      } catch (error) {
        console.log(error);
        // Handle the error by adding a message to the chat
        this.messages.push({
          id: this.messageId++,
          text: 'Sorry, something went wrong. Please try again later.',
          sender: 'bot',
        });
      }
    }
  },
  messageClass(message) {
    return message.sender === 'user' ? 'message-user' : 'message-bot';
  },
}

};
</script>

<style scoped lang="scss">
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.higher-z-index {
  z-index: 1000 !important;
  /* Ensure this is higher than the top bar's z-index */
}

.onelinebreak {
  display: flex;
  justify-content: space-between;
}
</style>
