<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <!-- Chat list section -->
        <v-col cols="3" class="chat-list">
          <v-list dense>
            <v-list-item v-for="chat in chats" :key="chat.id" @click="selectChat(chat.id)" :class="{'selected-chat': chat.id === selectedChatId}">
              <v-list-item-content>
                <v-list-item-title>{{ chat.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn icon @click="addChat" class="add-chat-btn">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>

        <!-- Chat window section -->
        <v-col cols="9" class="chat-window-container">
          <div class="chat-window" v-if="selectedChat">
            <div class="chat-messages" ref="chatContent">
              <div v-for="message in selectedChat.messages" :key="message.text" :class="{'patient-message': isPatientMessage(message), 'chatbot-message': !isPatientMessage(message)}">
                <div class="message-bubble">
                  <strong>{{ isPatientMessage(message) ? 'Patient' : 'Chatbot' }}:</strong> {{ message.text }}
                </div>
              </div>
            </div>
            <div class="chat-input">
              <v-btn icon @click="toggleRecording" class="circle-icon record-btn">
                <v-icon>{{ isRecording ? 'mdi-microphone-off' : 'mdi-microphone' }}</v-icon>
              </v-btn>
              <v-textarea
                v-model="displayMessage"
                ref="textField"
                placeholder="Type a message"
                auto-grow
                clearable
                @keyup.enter="sendMessage"
                rows="1"
                max-rows="4"
                class="custom-textarea"
              ></v-textarea>
              <v-btn icon @click="sendMessage" class="circle-icon send-btn">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </div>
            <div class="loading-indicator" v-if="isLoading">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </div>
          <div v-else class="no-chat-selected">
            <p>Please select a chat to view messages.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      chats: [],
      selectedChat: null,
      selectedChatId: null,
      message: '',
      displayMessage: '',
      isRecording: false,
      recognition: null,
      interimMessage: '',
      isLoading: false,
      errorMessage: ''
    };
  },
  async created() {
    this.fetchChats();
  },
  methods: {
    fetchChats() {
      this.$axios.get("/api/demo/chatService/chats")
        .then(response => {
          this.chats = response.data;
          if (this.chats.length > 0) {
            this.selectChat(this.chats[0].id);
          }
        })
        .catch(error => {
          console.error("Failed to fetch chats:", error);
          this.errorMessage = "Failed to fetch chats.";
        });
    },
    addChat() {
      const newChat = { name: `Patient ${this.chats.length + 1}` };
      this.$axios.post("/api/demo/chatService/chats", newChat)
        .then(response => {
          const createdChat = response.data;
          this.chats.push(createdChat);
          this.selectChat(createdChat.id);
        })
        .catch(error => {
          console.error("Failed to add new chat:", error);
          this.errorMessage = "Failed to add new chat.";
        });
    },
    selectChat(chatId) {
      this.selectedChat = this.chats.find(chat => chat.id === chatId) || null;
      this.selectedChatId = chatId;
    },
    sendMessage() {
      if (this.displayMessage.trim() && this.selectedChat) {
        const userMessage = { text: this.displayMessage.trim(), sender: 'patient' };
        this.selectedChat.messages.push(userMessage);
        this.displayMessage = '';
        this.adjustTextFieldHeight();
        this.scrollToBottom();
        this.isLoading = true;

        this.$axios.post(`/api/demo/chatService/chats/${this.selectedChatId}/messages`, userMessage)
          .then(() => {
            this.isLoading = false;
          })
          .catch(error => {
            console.error("Failed to send message:", error);
            this.errorMessage = "Failed to send message.";
            this.isLoading = false;
          });
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      if (this.recognition) {
        this.recognition.start();
      }
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
      }
    },
    adjustTextFieldHeight() {
      this.$nextTick(() => {
        const textField = this.$refs.textField;
        if (textField) {
          textField.style.height = 'auto';
          const scrollHeight = textField.scrollHeight;
          const maxHeight = parseInt(getComputedStyle(textField).lineHeight, 10) * 4;
          if (scrollHeight > maxHeight) {
            textField.style.height = `${maxHeight}px`;
            textField.scrollTop = textField.scrollHeight;
          } else {
            textField.style.height = `${scrollHeight}px`;
          }
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    },
    isPatientMessage(message) {
      return message.sender === 'patient';
    }
  }
};
</script>

<style>
body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}
.v-application--wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.v-container {
  padding-left: 0;
  padding-right: 0;
}
.chat-window-container {
  padding-left: 0;
  padding-right: 0;
}
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.patient-message {
  text-align: right;
}
.chatbot-message {
  text-align: left;
}
.message-bubble {
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  max-width: 80%;
  color: #000000;
}
.patient-message .message-bubble {
  background-color: #e0f7fa;
  margin-left: auto;
}
.chatbot-message .message-bubble {
  background-color: #eeeeee;
  margin-right: auto;
}
.chat-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.chat-input .v-textarea {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  resize: none;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 10px;
  color: #000000;
  border: none;
  box-shadow: none;
}
.chat-input .v-textarea .v-input__control {
  border-bottom: none !important;
}
.circle-icon {
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #000000;
}
.record-btn {
  background-color: #ff7043;
}
.send-btn {
  background-color: #29b6f6;
}
.add-chat-btn {
  background-color: #29b6f6;
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;
}
.chat-list {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.selected-chat {
  background-color: #eeeeee;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.custom-textarea textarea {
  overflow-y: hidden;
  resize: none;
  color: #000000;
}
.no-chat-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #777;
}
</style>
