<template>
  <div>
    <!-- The bar that toggles the chat window -->
    <div class="chatbot-bar" @click="toggleChat">
      <v-icon class="chat-icon">mdi-chat</v-icon>
      <span class="chat-text">Chat with us!</span>
    </div>

    <!-- The chat window -->
    <v-card v-if="chatOpen" class="chatbot-container">
      <v-card-title class="chatbot-title">
        ChatBot
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleChat">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="chatbot-messages">
        <div v-for="message in messages" :key="message.id" :class="messageClass(message)">
          {{ message.text }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row>
          <v-col cols="9">
            <v-text-field v-model="userMessage" @keyup.enter="sendMessage" label="Type a message" dense outlined
              class="chatbot-input"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn color="primary" @click="sendMessage">
              Send
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chatOpen: false,
      userMessage: '',
      messages: [],
      messageId: 1,
      loadingMessageId: null, // Track the loading message
    };
  },
  methods: {
    toggleChat() {
      this.chatOpen = !this.chatOpen;
    },
    async sendMessage() {
      if (this.userMessage.trim() !== '') {
        // Add the user's message to the chat
        this.messages.push({
          id: this.messageId++,
          text: this.userMessage,
          sender: 'user',
        });

        const messageToSend = this.userMessage;
        this.userMessage = ''; // Clear the input field

        // Add a loading message
        this.loadingMessageId = this.messageId++;
        this.messages.push({
          id: this.loadingMessageId,
          text: 'Waiting for response......',
          sender: 'bot',
        });

        try {
          // Send the message to the backend via Axios
          const response = await this.$axios({
            method: 'post',
            url: this.$axios.defaults.baseURL + '/api/chatbot/message',
            headers: { Authorization: localStorage.getItem('accessToken') },
            data: {
              message: messageToSend, // The message being sent to the backend
            },
          });

          // Remove the loading message
          this.messages = this.messages.filter(msg => msg.id !== this.loadingMessageId);

          // Process the backend response and add it to the chat
          this.messages.push({
            id: this.messageId++,
            text: response.data.answer,
            sender: 'bot',
          });

        } catch (error) {
          console.log(error);

          // Remove the loading message
          this.messages = this.messages.filter(msg => msg.id !== this.loadingMessageId);

          // If there's an error, add a message to notify the user
          this.messages.push({
            id: this.messageId++,
            text: 'Sorry, something went wrong. Please try again later.',
            sender: 'bot',
          });
        }
      }
    },
    messageClass(message) {
      return message.sender === 'user' ? 'message-user' : 'message-bot';
    },
  },
};
</script>

<style scoped>
/* Bar style for toggling the chat */
.chatbot-bar {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #6200ea;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  transition: transform 0.3s ease;
}

.chatbot-bar:hover {
  transform: scale(1.05);
}

.chat-icon {
  margin-right: 10px;
}

.chat-text {
  font-size: 16px;
  font-weight: bold;
}

/* Chat window */
.chatbot-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 400px;
  height: 500px;
  /* Set a fixed height even when empty */
  display: flex;
  flex-direction: column;
  z-index: 2000;
  transition: all 0.3s ease;
}

.chatbot-title {
  font-weight: bold;
}

.chatbot-messages {
  flex-grow: 1;
  /* Ensure the messages area grows with content */
  overflow-y: auto;
  margin-bottom: 10px;
}

.chatbot-input {
  flex-grow: 1;
  /* Make the input field take up available space */
}

.message-user {
  text-align: right;
  margin: 5px;
  padding: 8px;
  background-color: #3f51b5;
  color: white;
  border-radius: 10px;
}

.message-bot {
  text-align: left;
  margin: 5px;
  padding: 8px;
  background-color: #e0e0e0;
  border-radius: 10px;
}
</style>
