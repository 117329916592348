import axios from "axios";
//axios.defaults.baseURL = 'https://octchainbackend.link'
//axios.defaults.baseURL = 'http://localhost:3000'
//axios.defaults.baseURL = 'http://testoct.us-east-2.elasticbeanstalk.com'
axios.defaults.baseURL = 'https://octchain-backend-test-production.up.railway.app'


axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response.status === 401) {
      const { status, data } = await axios({
        method: "post",
        url: axios.defaults.baseURL + "/api/refresh",
        headers: {
          refresh: localStorage.getItem("refresh"),
        },
      }).catch((error) => console.log(error));
      if (status === 200) {
        localStorage.setItem("accessToken", "Bearer " + data.token);
      }
    }
    return error;
  }
);
export default axios;