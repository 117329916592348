<template>
  <v-app class="app-background">
    <v-container fluid class="container-background">
      <v-row style="height: 100vh;">
        <!-- Chat list section -->
        <v-col v-show="!isChatListCollapsed" cols="2" class="chat-list">
          <div class="chat-list-header">
            <v-btn icon @click="toggleChatList" class="collapse-chat-button">
              <v-icon>mdi-menu-left</v-icon>
            </v-btn>
            <v-btn icon @click="addChat" class="add-chat-button">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div class="chat-items">
            <div v-for="chat in chats" :key="chat.id" @click="selectChat(chat.id)" :class="['chat-item', { 'selected-chat-item': selectedChatId === chat.id }]">
              <div class="chat-item-title">{{ chat.name }}</div>
            </div>
          </div>
        </v-col>
        <!-- Toggle button and add button for collapsed state -->
        <div v-if="isChatListCollapsed" class="collapsed-buttons">
          <v-btn icon @click="toggleChatList" class="expand-chat-button">
            <v-icon>mdi-menu-right</v-icon>
          </v-btn>
          <v-btn icon @click="addChat" class="add-chat-button-collapsed">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <!-- Chat window section -->
        <v-col :cols="isChatListCollapsed ? 10 : 10" class="chat-window" :class="{'chat-window-collapsed': isChatListCollapsed}">
          <!-- Chat title section -->
          <div v-if="selectedChat" class="chat-title">
            {{ selectedChat.name }}
          </div>
          <div v-if="selectedChat" class="chat-content" ref="chatContent">
            <div v-for="message in selectedChat.messages" :key="message.id" :class="['message', { 'patient-message': isPatientMessage(message), 'doctor-message': !isPatientMessage(message) }]">
              <div class="message-sender">
                <strong>{{ isPatientMessage(message) ? 'Patient' : 'Chatbot' }}:</strong>
              </div>
              <div class="message-content">
                <div class="message-text">
                  {{ message.text }}
                </div>
              </div>
            </div>
            <div v-if="isLoading" class="message doctor-message">
              <div class="message-content loading-indicator">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <v-btn icon @click="toggleRecording" class="microphone-button">
              <v-icon>{{ isRecording ? 'mdi-microphone-off' : 'mdi-microphone' }}</v-icon>
            </v-btn>
            <textarea
              v-model="displayMessage"
              ref="textField"
              placeholder="Type a message"
              class="message-input"
              @input="adjustTextFieldHeight"
              @keyup.enter="sendMessage"
            ></textarea>
            <v-btn icon @click="sendMessage" class="send-button">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      chats: [],
      selectedChat: null,
      selectedChatId: null,
      displayMessage: '',
      isRecording: false,
      recognition: null,
      isLoading: false,
      errorMessage: '',
      isChatListCollapsed: false,
    };
  },
  async created() {
    try {
      const response = await this.$axios.get('/api/demo/chatService/chats', {
        headers: { Authorization: localStorage.getItem('accessToken') }
      });
      this.chats = response.data;
      if (this.chats.length > 0) {
        this.selectChat(this.chats[0].id);
      }
    } catch (error) {
      console.error('Failed to fetch chats:', error);
      this.errorMessage = 'Failed to fetch chats.';
    }
  },
  methods: {
    toggleChatList() {
      this.isChatListCollapsed = !this.isChatListCollapsed;
    },
    async addChat() {
      const newChat = { name: `Chat ${this.chats.length + 1}` };
      try {
        const response = await this.$axios.post('/api/demo/chatService/chats', newChat, {
          headers: { Authorization: localStorage.getItem('accessToken') }
        });
        const createdChat = response.data;
        this.chats.push(createdChat);
        this.selectChat(createdChat.id);
      } catch (error) {
        console.error('Failed to add new chat:', error);
        this.errorMessage = 'Failed to add new chat.';
      }
    },
    selectChat(chatId) {
      this.selectedChat = this.chats.find(chat => chat.id === chatId) || null;
      this.selectedChatId = chatId;
      this.scrollToBottom();
    },
    async sendMessage() {
      if (this.displayMessage.trim() && this.selectedChat) {
        const userMessage = { id: uuidv4(), text: this.displayMessage.trim(), sender: 'patient' };
        this.selectedChat.messages.push(userMessage);
        this.displayMessage = '';
        this.adjustTextFieldHeight();
        this.scrollToBottom();
        this.isLoading = true;

        try {
          await this.$axios.post(`/api/demo/chatService/chats/${this.selectedChatId}/messages`, userMessage, {
            headers: { Authorization: localStorage.getItem('accessToken') }
          });
        } catch (error) {
          console.error('Error saving message to backend:', error);
        }

        let botMessage = '';
        try {
          const response = await this.$axios.post('/api/demo/chatbot/chat', { question: userMessage.text }, {
            headers: { Authorization: localStorage.getItem('accessToken') }
          });
          botMessage = response.data.answer;

          const botResponse = { id: uuidv4(), text: botMessage, sender: 'doctor' };
          this.selectedChat.messages.push(botResponse);
          this.scrollToBottom();

          await this.$axios.post(`/api/demo/chatService/chats/${this.selectedChatId}/messages`, botResponse, {
            headers: { Authorization: localStorage.getItem('accessToken') }
          });
        } catch (error) {
          console.error('Error getting response from chatbot or saving it to backend:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      if (this.recognition) {
        this.recognition.start();
      }
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
      }
    },
    adjustTextFieldHeight() {
      this.$nextTick(() => {
        const textField = this.$refs.textField;
        if (textField) {
          textField.style.height = '20px';
          const scrollHeight = textField.scrollHeight;
          textField.style.height = `${scrollHeight}px`;
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        if (chatContent) {
          chatContent.scrollTop = chatContent.scrollHeight;
        }
      });
    },
    isPatientMessage(message) {
      return message.sender === 'patient';
    }
  },
  watch: {
    selectedChat(newChat) {
      if (newChat) {
        this.scrollToBottom();
      }
    }
  }
};
</script>



<style scoped>
.app-background {
  background-color: white; /* White */
}

.container-background {
  background-color: white; /* White */
}

.chat-list { 
  height: 100vh;
  background-color: #f5f5f5; /* Light Grey */
  display: flex;
  flex-direction: column;
  border-radius: 0; /* Remove border radius */
}

.chat-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;  
}

.chat-items {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-item {
  cursor: pointer;
  background-color: #f5f5f5; /* Light Grey */
  padding: 10px; 
}

.chat-item:hover {
  background-color: #e0e0e0; /* Darker Grey */
}

.selected-chat-item {
  background-color: #d3d3d3; /* Selected Chat Item Grey */
}

.chat-item-title {
  font-weight: bold; 
}

.add-chat-button {
  margin: 0; 
}

.collapse-chat-button {
  margin: 0; 
}

.collapsed-buttons {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.add-chat-button-collapsed {
  margin-left: 10px; 
}

.chat-window {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white; /* White */
  border-radius: 0; /* Remove border radius */
}

.chat-window-collapsed {
  margin: 0 auto;
  width: calc(100% - 96px); /* Adjust this value to achieve desired margins */ 
}

.chat-title {
  background-color: white; /* White */
  padding: 16px;
  font-size: 18px;
  font-weight: bold; 
}

.chat-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: white; /* Change to white */
}

.message {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.patient-message {
  align-items: flex-end; 
}

.patient-message .message-content {
  background-color: #e0f7fa; /* Light Blue */
  padding: 8px;
  border-radius: 4px;
  max-width: 70%;
  align-self: flex-end; 
}

.doctor-message .message-content {

  padding: 8px;
  border-radius: 4px;
  max-width: 70%;
  align-self: flex-start; 
}

.message-sender {
  font-weight: bold;
  margin-bottom: 4px; 
}

.message-text {
  white-space: pre-wrap; 
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 16px; 
  background-color: #fff; /* White */
}

.microphone-button {
  margin-right: 8px; 
}

.message-input {
  flex: 1;
  margin-right: 8px;
  resize: none;
  overflow: hidden;
  height: 20px;
  min-height: 36px;
  max-height: 150px; 
}

.send-button {
  margin-left: 8px;
}

.loading-indicator {
  text-align: center;
  padding: 16px; 
}

textarea.message-input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px; 
}
</style>
